.feedback-button-container {
    padding-top: 10px;
    padding-bottom: 10px;
}

.feedback-button-border {
    padding: 5px;
    border-color: #D4A265;
    border-width: 3px;
    border-style: solid;
    border-radius: 35px;
    width: 280px;
    height: 64px;
}

.feedback-button-border button {
    background-color: #1F4430;
    color: white;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    font-size: 28px;
    font-weight: bold;
}