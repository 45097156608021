h1 {
    color: #1F4430;
}

h2 {
    color: #1F4430;
}

.question {
    background-color: #1F4430;
}

.answer:hover label{
    color: #e4A265;
}

.answers input[type=radio]{
    position: absolute;
    visibility: hidden;
}

.answers .check{
    cursor: pointer;
    border: 2px solid #D4A265;
    border-radius: 0;
    height: 12px;
    width: 12px;
    margin: 3px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
    transform: rotate(45deg);
}


.answer {
    /*margin-left: 20px;*/
    /*padding-top: 5px;*/
    /*padding-bottom: 5px;*/
    min-height: 40px;
}


.answer label {
    color: gray;
    padding-left: 20px;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
}

.answer:hover .check {
    border: 5px solid #e4A265;
}

.answer .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 0;
    height: 08px;
    width: 08px;
    top: 2px;
    left: 2px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
    transform: rotate(90deg);
}

input[type=radio]:checked ~ .check {
    border: 4px solid #D4A265;
}

input[type=radio]:checked ~ .check::before{
    background: #D4A265;
}

input[type=radio]:checked ~ label{
    color: #D4A265;
}

.check {
    box-sizing: content-box;
}