.success-content {
    margin-top: 30px;
    text-align: center;
}

.success-content i {
    font-size: 10em;
    color: #1F4430;
}

.success-content .title {
    margin-top: 20px;
    font-size: 3em;
}