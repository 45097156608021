/* component */

.star-rating {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #D4A265;
    border-radius: 4px;
    display: flex;
    flex-direction: row-reverse;
    font-size: 1.5em;
    justify-content: space-around;
    padding: 0 .2em;
    text-align: center;
    width: 100%;
}

.star-rating input {
    display: none;
}

.star-rating label {
    color: #ccc;
    cursor: pointer;
}

.star-rating :checked ~ label {
    color: #f90;
}

.star-rating label:hover,
.star-rating label:hover ~ label {
    color: #fc0;
}

@media screen and (min-width: 601px) {
    .star {
        font-size: 40px;
    }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
    .star {
        font-size: 25px;
    }
}
