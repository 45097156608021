.failure-content {
    margin-top: 30px;
    text-align: center;
}

.failure-content i {
    font-size: 10em;
    color: red;
}

.failure-content .title {
    margin-top: 20px;
    font-size: 3em;
}