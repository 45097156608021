.box {
    width: 500px;
}

.rating {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    margin-top: 10px;
    /*border-style: solid;*/
    /*border-color: #D4A265;*/
    /*border-width: 2px;*/
    box-sizing: border-box;
    /*background: linear-gradient(to right, #f00, #ff0, #0f0);*/
    /*background-color: #1F4430;;*/
}

.rating input {
    display: none;
}

.rating label {
    display: block;
    cursor: pointer;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    background: #fff;
    color: #000;
    font-size: 20px;
    /*border-collapse: collapse;*/
    /*border: 2px solid #D4A265;*/

    box-shadow:
            2px 0 0 0 #D4A265,
            0 2px 0 0 #D4A265,
            2px 2px 0 0 #D4A265,   /* Just to fix the corner */
            2px 0 0 0 #D4A265 inset,
            0 2px 0 0 #D4A265 inset;
}

.rating input[type="radio"]:hover ~ label,
.rating input[type="radio"]:checked ~ label {
    background: transparent;
}

.rating-string-box {
    border: 2px solid #D4A265;
    padding: 10px;
    color: black;
    margin-top: 20px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    transition: 0.5s;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
}

/*.rating-string-box:after {*/
/*    position: absolute;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    border-top: 2px solid #D4A265;*/
/*    border-right: 0px solid #D4A265;*/
/*    border-bottom: 0px solid #D4A265;*/
/*    border-left: 2px solid #D4A265;*/
/*    top: 0%;*/
/*    left: 50%;*/
/*    margin-left: -10px;*/
/*    margin-top: -10px;*/
/*    content: '';*/
/*    transform: rotate(45deg);*/
/*    background-color: inherit;*/
/*}*/

.rating-string-box-arrow {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border-top: 2px solid #D4A265;
    border-right: 0px solid #D4A265;
    border-bottom: 0px solid #D4A265;
    border-left: 2px solid #D4A265;
    top: 10vw;
    left: 20vw;
    /*left: 40%;*/
    margin-left: -10px;
    /*margin-top: -10px;*/
    content: '';
    transform: rotate(45deg);
    background-color: red;
    z-index: 1;
}